// import { useRouteMatch } from "react-router-dom";
import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import NoRowsOverlay from "../../components/NoRowsOverlays";

const StatusButton = (props) => {
  let { params } = props;
  console.log(props, "StatusButton");
  return (
    <div>
      {params?.row?.isFandFappActive && (
        <button className={`btn btn-sm btn_table btn-success`}>Active</button>
      )}
      {!params?.row?.isFandFappActive && (
        <button className={`btn btn-sm btn_table btn-danger`}>Deactive</button>
      )}
    </div>
  );
};

const columns = [
  // { field: "id", headerName: "No.", width: 30 },
  {
    field: "name",
    headerName: "Name",
    width: 230,
  },
  { field: "email", headerName: "Email", width: 150 },
  { field: "phone", headerName: "Telephone", width: 150 },
  { field: "creationDate", headerName: "Creation Date", width: 150 },
  { field: "activationDate", headerName: "Activation Date", width: 150 },
  { field: "deactivationDate", headerName: "Deactivation Date", width: 150 },
  { field: "deletionDate", headerName: "Deletion Date", width: 150 },
  {
    field: "isFandFappActive",
    headerName: "Status",
    width: 250,
    renderCell: (params) => <StatusButton params={params} />,
  },
];
export default function FriendsFamily({ careSiteId }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    getClientbyId();
  }, []);

  let params = useParams();
  console.log(params, "params");

  const getClientbyId = () => {
    axios({
      url: `${process.env.REACT_APP_SUPERADMIN_BASEURL}/getFandF?careHomeId=${careSiteId}`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("superadmin_token") },
    })
      .then((res) => {
        let keycontactData = res?.data?.data;
        let fandfdata = [];
        console.log(keycontactData, "keycontactData");
        keycontactData.forEach((element) => {
          fandfdata.push({ ...element.keycontact, mid: element._id });
        });
        setRows(fandfdata);
      })
      .catch((error) => console.log(`Error: ${error}`));
  };
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={rows}
        autoHeight
        getRowId={(row) => row?._id}
        slots={{
          noRowsOverlay: NoRowsOverlay,
        }}
      />
    </div>
  );
}
