import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { useHistory } from "react-router-dom";
import { FaRegSun } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import { twoDecimalPlaces } from "../../utils/utilsHandlerFunctions";
import { limitToTwoDecimal } from "../../Apis/commonApis";
import { getFullName } from "../../utils/CommonFunctions";

const chatUserImg = "/images/chatUser.svg";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "wageProfile",
    numeric: false,
    disablePadding: true,
    label: "Wage Profile",
  },
  {
    id: "StartDate",
    numeric: false,
    disablePadding: true,
    label: "Start Date",
  },
  {
    id: "RegularHrs",
    numeric: false,
    disablePadding: true,
    label: "Regular Hrs",
  },
  {
    id: "OvertimeHrs",
    numeric: false,
    disablePadding: true,
    label: "Overtime Hrs",
  },
  {
    id: "TotalHrs",
    numeric: false,
    disablePadding: true,
    label: "Total Hrs",
  },
  // {
  //   id: "Hrrate",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Hr rate",
  // },
  {
    id: "normalRate",
    numeric: false,
    disablePadding: true,
    label: "Normal Rate",
  },
  {
    id: "OTRate",
    numeric: false,
    disablePadding: true,
    label: "OT Rate",
  },
  {
    id: "GrossWage",
    numeric: false,
    disablePadding: true,
    label: "Gross  Wage",
  },
  {
    id: "NetWage",
    numeric: false,
    disablePadding: true,
    label: "Net Wage",
  },
  {
    id: "arrow",
    numeric: true,
    disablePadding: true,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        backgroundColor: "#fff",
        opacity: 1,
        position: "sticky",
        top: 0,
      }}
    >
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function Overview(props) {
  const { rows, searchText, handleDetailShowHide } = props;

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const history = useHistory();

  function redirectTo(id, row, page) {
    let wageData = {};
    if (row !== "") {
      wageData = {
        careTeamId: row._id,
        name: getFullName(row),
        image: row.image,
        startDate: row.startDate,
        designation: row.designation ? row.designation : "-",
        manager: row.manager ? row.manager : "-",
        regularHrs: row.weeklyPlannedHours,
        empPayrollFreq: row.empPayrollFreq,
        hrlyRate: row.wagesPerhour,
        overtimeRate: row.overtimeRate,
      };
    }
    history.push(`/admin/paywages/${page}/${id}`, wageData);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{ width: "100%", mb: 2, boxShadow: "none", overflow: "hidden" }}
      >
        <TableContainer sx={{ height: "52vh" }}>
          <Table stickyHeader aria-labelledby="sticky table" size={"small"}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .filter((item) => {
                  const searchTerm = searchText.toLowerCase();
                  const fullName = getFullName(item).toLowerCase();
                  return fullName?.includes(searchTerm);
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const regHrs = row.regularHours > 0 ? row.regularHours : 0;
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell>
                        <span className="profle_img_box">
                          <img
                            alt=""
                            className={`profile_img_table ${row?.image?.includes('chatUser') ? "forImagecareteam" : ""
                              }`}
                            src={row.image ? row.image : chatUserImg}
                          />
                        </span>
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {getFullName(row)}
                      </TableCell>
                      <TableCell>
                        <FaRegSun
                          size={20}
                          onClick={(e) => {
                            redirectTo(row._id, row, "wageProfile");
                          }}
                        />
                      </TableCell>
                      <TableCell padding="none">{row.startDate}</TableCell>
                      <TableCell padding="none">
                        {twoDecimalPlaces(regHrs)}
                      </TableCell>
                      <TableCell padding="none">
                        {twoDecimalPlaces(row.overTimeHours)}
                      </TableCell>
                      <TableCell padding="none">
                        {twoDecimalPlaces(row?.totalHours)}
                      </TableCell>
                      {/* <TableCell padding="none">
                        {twoDecimalPlaces(row.wagesPerhour)}
                      </TableCell> */}
                      <TableCell padding="none">
                        {twoDecimalPlaces(row.wagesPerhour)}
                      </TableCell>
                      <TableCell padding="none">
                        {twoDecimalPlaces(row.overtimeRate)}
                      </TableCell>
                      <TableCell padding="none">
                        {twoDecimalPlaces(row.grossWage)}
                      </TableCell>
                      <TableCell padding="none">
                        {twoDecimalPlaces(row.netWage)}
                      </TableCell>
                      <TableCell>
                        <MdKeyboardArrowRight
                          size={30}
                          onClick={(e) => {
                            handleDetailShowHide(row._id);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 22 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6}></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[8, 16, 32]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
