import * as React from "react";
import { useRouteMatch } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import ClipboardCopy from "../../components/copyToClipboard";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "TimeStamp",
    numeric: false,
    disablePadding: true,
    label: "TimeStamp",
  },
  {
    id: "EventType",
    numeric: false,
    disablePadding: true,
    label: "User ID",
  },
  {
    id: "EventID",
    numeric: false,
    disablePadding: true,
    label: "IP Address",
  },
  {
    id: "OSType",
    numeric: false,
    disablePadding: true,
    label: "OS Type ",
  },
  {
    id: "BrowserType",
    numeric: false,
    disablePadding: true,
    label: "Browser Type ",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: true,
    label: "Location",
  },
  {
    id: "EventID",
    numeric: false,
    disablePadding: true,
    label: "Event ID ",
  },
  {
    id: "EventType",
    numeric: false,
    disablePadding: true,
    label: "Event Type ",
  },
  {
    id: "Event Result",
    numeric: false,
    disablePadding: true,
    label: "Event Result ",
  },
  {
    id: "Source",
    numeric: false,
    disablePadding: true,
    label: "Source",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function AllEvents(props) {
  const { rows } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event, name) => {
    let newSelected = [];

    if (event.target.checked) {
      newSelected.push(name);
    } else {
      newSelected = [];
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // specific event details shown
  const [event, setEvent] = React.useState({});

  return (
    <div className="d-flex position-relative align-items-start">
      <Box sx={{ width: "65%" }}>
        <Paper
          sx={{
            width: "100%",
            mb: 2,
            mt: 4,
            boxShadow: "none",
            borderRight: "inset",
          }}
        >
          <TableContainer>
            <Table
              sx={{ minWidth: 400, width: "95%" }}
              aria-labelledby="tableTitle"
              size={"small"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        onClick={(event) => {
                          handleClick(event, row.id);
                          setEvent(row);
                        }}
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {new Date(row.time)
                            .toLocaleString("en-GB")
                            .slice(0, -3)}&ensp;
                        </TableCell>
                        <TableCell padding="none">
                          {row.uid ? row.uid : '-'}&ensp;
                        </TableCell>
                        <TableCell padding="none">{row.ip ? row.ip : '-'}</TableCell>
                        <TableCell padding="none">{row.os ? row.os : '-'}</TableCell>
                        <TableCell padding="none">{row.browser ?? '-'}</TableCell>
                        <TableCell padding="none">{row.location ?? '-'}</TableCell>
                        <TableCell padding="none">{row.eventId ? row.eventId : '-'}&ensp;</TableCell>
                        <TableCell padding="none">{row.eventType?.replace(".", "-")}&ensp;</TableCell>
                        <TableCell padding="none">{row.eventResult}</TableCell>
                        <TableCell padding="none">{row.source}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 40 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      {/* <div style={{ width: "15%" }}>
        <div className=" mt-5 mx-2 d-flex justify-content-between">
          <p className="m-0">Event Data</p>
                    <ClipboardCopy copyText={JSON.stringify(event, null, 2)} />
        </div>
        <div className="mx-2 mt-3">
          <p className="events_desc">{JSON.stringify(event, null, 2)} </p>
        </div>
      </div> */}

      <select id="event" name="event" className="event_filter_drop">
        <option value="all">All</option>
        <option value="saab">Clients</option>
        <option value="fiat">Subscriptions</option>
        <option value="audi">Payments</option>
        <option value="audi">Invoices</option>
        <option value="audi">Coupons</option>
        <option value="audi">Friends</option>
      </select>
    </div>
  );
}
