import React, { useEffect, useState } from 'react';
import AllEvents from '../AllActivity/AllEvents';
import { httpSuperAdmin } from "../../Apis/commonApis";
import { useLocation } from "react-router-dom";

export default function Event(props) {
    const { careSite } = props;
    const location = useLocation();
    // const myparam = location.state.params;

    const [rows, setRows] = useState([]);
    const [stripeEvents, setStripeEvents] = useState([]);
    const [goCardEvents, setGoCardEvents] = useState([]);
    const [manualEvents, setManualEvents] = useState([]);

    useEffect(() => {
        if (careSite) {
            getStripeEvents();
            getGoCardEvents();
            getManualEvents();
        }
    }, [careSite]);

    useEffect(() => {
        let newArray = [];
        newArray = newArray.concat(stripeEvents, goCardEvents, manualEvents);
        setRows(newArray);
    }, [stripeEvents, goCardEvents, manualEvents]);

    const getStripeEvents = async () => {
        const result = await httpSuperAdmin('listAllEvents', null);
        let eventData = result.data.data;
        let rowData = [];
        eventData.forEach((elem, i) => {
            if (elem.data?.object?.subscription === careSite?.subscriptionId) {
                rowData.push({
                    id: elem.id,
                    eventType: elem.type,
                    time: elem.created,
                    source: 'Stripe',
                    eventId: `ST_1001SUB100${i + 1}`
                })
            }
        });
        setStripeEvents(rowData);
    }

    const getGoCardEvents = async () => {
        const result = await httpSuperAdmin('gocardLessEvents', null);
        let eventData = result.data.events;
        let rowData = [];
        eventData.forEach((elem, i) => {
            if (elem.links?.subscription === careSite?.subscriptionId) {
                rowData.push({
                    id: elem.id,
                    eventType: elem.action,
                    time: elem.created_at,
                    source: 'GoCardless',
                    eventId: `GO_1001SUB100${i + 1}`
                });
            }
        });
        setGoCardEvents(rowData)
    }

    const getManualEvents = async () => {
        const result = await httpSuperAdmin('getManualEvents', null);
        let eventData = result.data;
        let rowData = [];
        eventData.forEach((elem, i) => {
            if (elem.action === null && elem.careSiteId === careSite?.careSiteId) {
                rowData.push({
                    id: elem._id,
                    eventType: elem.eventType,
                    time: elem.createdAt,
                    source: 'Manual',
                    eventId: elem.eventId
                })
            }
        });
        setManualEvents(rowData)
    }

    return (
        <AllEvents rows={rows} />
    )
}
