import React, { useState, useEffect } from "react";
import axios from "axios";
import ClientsTable from "./ClientsTable";
import { IoIosArrowForward } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { filterData } from "../../utils/TableSearchFilter";
import { BsSearch } from "react-icons/bs";
import { Modal, Dropdown, DropdownButton } from "react-bootstrap";
import swal from "sweetalert";
import { ShowSwal } from "../../utils/alertSwal";
import { httpSuperAdmin } from "../../Apis/commonApis";
import DataGridTable from "../../utils/DataGridTable";
import { formatDate } from "../../utils/CommonFunctions";
import { twoDecimalPlaces } from "../../utils/utilsHandlerFunctions";

export default function ClientsOfSuperadmin() {
  let [rowsData, setrowData] = useState([]);
  let [selectedRows, setSelectedRows] = useState([]);
  const [searchTxt, setSearchTxt] = useState("");
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  useEffect(() => {
    adminlisting();
  }, []);

  useEffect(() => {
    if (rowsData.length > 0 && selectedRows.length > 0) {
      const selectedIds = selectedRows?.map(item => item.ownerId);
      selectMultipledata(selectedIds)
    }
  }, [rowsData]);

  let roleAccess = JSON.parse(localStorage.getItem("__csadmin__d"));

  function adminlisting() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_SUPERADMIN_BASEURL}/getOwners`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("superadmin_token"),
      },
    };
    axios(config)
      .then(function (response) {
        const responseData = response.data.data;
        let planId = [];
        let ownersData = [];
        responseData.forEach((elem) => {
          elem.subscriptionPlanData?.forEach((item) => {
            if (item.goCardLess_customer_id) {
              planId.push(item.goCardLess_customer_id);
            }
            if (item.stripe_customer_id) {
              planId.push(item.stripe_customer_id);
            }
          });
        });
        responseData?.forEach((element) => {
          ownersData.push({
            ownerId: element._id,
            careSiteId: element?.subscriptionPlanData?.map((item) => item._id),
            id: element._id,
            firstName: element.firstName?.trim() ?? "",
            lastName: element.lastName?.trim() ?? "",
            email: element.email ?? "",
            companyName: element?.subscriptionPlanData[0]?.companyName ?? "",
            creationDate: formatDate(element.createdAt) ?? "",
            noofSites: element?.subscriptionPlanData.length ?? "",
            monthlyFees: twoDecimalPlaces(element?.subscriptionPlanData.reduce((acc, cur) => acc + cur.total_amount, 0) ?? ""),
            status: element.subscriptionStatus == "true"
              ? "Active"
              : element.subscriptionStatus == "false"
                ? "Inactive"
                : "deactivated",
            redirectionLink: `/superadmin/clientdetails/${element?.subscriptionPlanData[0]?.companyName}/${element._id}/overview`,
            state: planId ?? "",
          });
        });
        setrowData(ownersData.reverse());
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function selectMultipledata(ids) {
    const arrayOfIds = rowsData?.filter((item) => ids.includes(item.ownerId))
      .map((item) => {
        let idsData = [];
        if (item.careSiteId.length > 0) {
          item.careSiteId?.forEach((element) => {
            idsData.push({
              ownerId: item.ownerId,
              careSiteId: element,
              status: item.status,
            });
          });
        } else {
          idsData.push({
            ownerId: item.ownerId,
            status: item.status,
          });
        }
        return idsData;
      })
      .flat();
    setSelectedRows(arrayOfIds);
  }

  async function deleteConfermation() {
    if (selectedRows?.length == 0) {
      ShowSwal(false, "Please Select at least one client");
    } else {
      swal({
        title: "Are you sure??",
        text: `click on Yes to Continue`,
        buttons: {
          cancel: "Cancel",
          succeed: "Yes",
        },
      }).then((result) => {
        if (result) {
          deletemultiple();
        } else {
          console.log("rejected");
        }
      });
    }
  }

  const deletemultiple = async () => {
    const deletecaresitesIds = selectedRows?.filter(
      (item) => item.status === "Active"
    );
    const deleteCareSiteBefore7YearIds = selectedRows?.filter(
      (item) => item.status === "deactivated"
    );

    if (deletecaresitesIds.length > 0) {
      const res = await httpSuperAdmin("deleteCareSite", {
        ids: deletecaresitesIds,
        clientModule: true
      });
      let resMsg = Array.isArray(res.message)
        ? res.message.join("\n")
        : res.message;
      ShowSwal(res.status, resMsg);
      adminlisting();
      if (res.status) adminlisting();
    }

    if (deleteCareSiteBefore7YearIds.length > 0) {
      const res = await httpSuperAdmin("deleteCareSiteBefore7Year", {
        ids: deleteCareSiteBefore7YearIds,
        clientModule: true
      });
      let resMsg = Array.isArray(res.message)
        ? res.message.join("\n")
        : res.message;
      ShowSwal(res.status, resMsg);
      // setSelectedRows([]);
      if (res.status) adminlisting();
    }
  };

  const columns = [
    { field: "firstName", headerName: "First Name", width: 120 },
    { field: "lastName", headerName: "Last Name", flex: 1, minWidth: 120 },
    { field: "email", headerName: "Email", flex: 1, minWidth: 120 },
    {
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
      minWidth: 120,
    },

    { field: "creationDate", headerName: "Creation Date", minWidth: 120 },
    { field: "noofSites", headerName: "No. of Sites", minWidth: 120 },
    { field: "monthlyFees", headerName: "Fees", minWidth: 90 },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (fieldData) => <StatusBtn fieldData={fieldData} />,
    },
    {
      id: "arrow",
      width: 50,
      numeric: true,
      disablePadding: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (fieldData) => <RedirectionIcon fieldData={fieldData} />,
    },
  ];

  const filteredData = filterData(
    columns?.map((item) => ({
      id: item.field,
      label: item.headerName,
    })),
    rowsData,
    searchTxt
  );

  return (
    <>
      <div className="page-wrapper" style={{ height: "80vh" }}>
        {roleAccess?.role?.map((roletype) => {
          const clientSectionAccess = roletype.Modules[2];
          if (clientSectionAccess?.access !== "full")
            return <div className="clickOff"></div>;
        })}
        <div className="card">
          <div className="card-body">
            <div className="row mb-2 justify-content-end">
              <div className="searchForm careSearch">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={searchTxt}
                  onChange={(e) => setSearchTxt(e.target.value)}
                />
                <BsSearch className="icon me-2" />
              </div>
              <div
                className="col-md-6 btns_head"
                style={{ textAlign: "right" }}
              >
                <DropdownButton id="dropdown-basic-button" title="Actions">
                  <Dropdown.Item onClick={deleteConfermation}>
                    Delete{" "}
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
            {/* <ClientsTable
              rows={rowsData}
              adminlisting={adminlisting}
            /> */}
            <DataGridTable
              columns={columns}
              rows={filteredData}
              handleRowSelection={selectMultipledata}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const RedirectionIcon = (props) => {
  const { fieldData } = props;
  const history = useHistory();
  return (
    <IoIosArrowForward
      style={{ cursor: "pointer", fontSize: "20px" }}
      onClick={() => {
        history.push(fieldData.row?.redirectionLink);
      }}
    />
  );
};

const StatusBtn = (props) => {
  const { fieldData } = props;
  const status = fieldData?.row?.status;
  return (
    <button
      className={`btn table_btn btn-${status === "Active" ? "success" : "danger"
        } btn-sm`}
    >
      {status ?? "-"}
    </button>
  );
};
